<template>
  <div id="body">
    <div class="header">
      <div>
        <p style="color: #ff5a21">{{parentNum}}</p>
        <p>家长总数量</p>
      </div>
      <div>
        <p style="color: #3aa0ff">{{stuNum}}</p>
        <p>学生总数量</p>
      </div>
      <div>
        <p style="color: #1ac574">{{testNum}}</p>
        <p>总检测次数</p>
      </div>
      <div>
        <p style="color: #30cadc">{{testPeople}}</p>
        <p>总检测人数</p>
      </div>
      <div>
        <p style="color: #ff5a21">{{todayParentNum}}</p>
        <p>今日新增家长总数量</p>
      </div>
      <div>
        <p style="color: #3aa0ff">{{todayStuNum}}</p>
        <p>今日新增学生总数量</p>
      </div>
      <div>
        <p style="color: #1ac574">{{todayTestNum}}</p>
        <p>今日检测总次数</p>
      </div>
      <div>
        <p style="color: #30cadc">{{todayTestPeople}}</p>
        <p>今日检测总人数</p>
      </div>
    </div>
    <div class="query">
      <div class="queryLeft">
        <div>地区：
          <el-select class="select"  size="medium" v-model="searchCity" placeholder="请选择地区"  @change="city">
            <el-option v-for="item in cityList" :key="item.scity" :label="item.cname" :value="item.scity">
            </el-option>
          </el-select>
        </div>
        <div style="width: 360px">学校：
          <el-select class="select" size="medium" v-model="searchSchool"  placeholder="请选择学校" >
            <el-option v-for="item in schoolList" :key="item.ID" :label="item.SchoolName" :value="item.ID">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="queryRight">
       <el-button @click="reset()">重置</el-button>
        <el-button type="primary" @click="schoolDataSearch(1)">查询</el-button>
      </div>
    </div>
    <div class="ClassInfo_box">
      <div class="chartTitle">
        <p class="facility">学校数据统计</p>
      </div>

      <el-table :data="data" style="width: 100%" v-loading="loading">
        <el-table-column prop="ID" label="学校ID" align="center" width="100"></el-table-column>
        <el-table-column prop="SchoolName" label="学校名称" align="center"></el-table-column>
        <el-table-column prop="InsertTime" label="注册时间	" align="center"></el-table-column>
        <el-table-column prop="parentNum" label="家长总数" align="center"></el-table-column>
        <el-table-column prop="stuNum" label="学生总数	" align="center"></el-table-column>
        <el-table-column prop="schoolCountTestNum" label="总检测次数" align="center"></el-table-column>
        <el-table-column prop="schoolCountTestPeople" label="总检测人数	" align="center"></el-table-column>
        <el-table-column prop="schoolTodayCountTestNum " label="今日检测次数" align="center"></el-table-column>
        <el-table-column prop="schoolTodayCountTestPeople" label="今日检测人数" align="center"></el-table-column>
      </el-table>
      <div class="block">
        <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
          layout="total, prev, pager, next, jumper"  :total="CountNum" :current-page.sync="currpage" style="margin-top: 20px">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    schoolData,
    testData,
    citySearch,
    schoolSearch,
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
        parentNum: '', // 家长总数量
        stuNum: '', // 学生总数量
        testNum: '', // 总检测次数
        testPeople: '', // 总检测人数
        todayParentNum: '', // 今日新增家长总数量
        todayStuNum: '', // 今日新增学生总数量
        todayTestNum: '', // 	今日检测总次数
        todayTestPeople: '', // 今日检测总人数
        data: [],
        loading: false, //加载框
        currpage: 1, // 初始页
        pagesize: 12, // 每页显示条目个数
        CountNum: 0, // 总条数
        cityList: [], // 城市数组
        schoolList: [], // 学校数组
        cityid: "", // 市
        searchCity: "", // 下拉城市
        searchSchool:"" // 下拉学校
      }
    },
    mounted() {
      this.testData() // 获取头部信息数据
      this.schoolData(1) // 默认今日数据
      this.citySearch() // 查询地区
    },
    methods: {
      reset() { //重置按钮
        this.searchCity = "";
        this.searchSchool = "";
        this.schoolData(1);
        this.currpage = 1;
        this.schoolList = [];
          // :current-page.sync="currpage" 这么写才能重置页码
      },
      testData() { // 头部设备数据
        testData().then(res => {
          this.parentNum = res.data.parentNum // 家长总数量
          this.stuNum = res.data.stuNum // 学生总数量
          this.testNum = res.data.testNum // 总检测次数
          this.testPeople = res.data.testPeople // 总检测人数
          this.todayParentNum = res.data.todayParentNum // 今日新增家长总数量
          this.todayStuNum = res.data.todayStuNum // 今日新增学生总数量
          this.todayTestNum = res.data.todayTestNum // 	今日检测总次数
          this.todayTestPeople = res.data.todayTestPeople // 今日检测总人数
        }).catch(err => {
          console.log(err)
        })
      },
      schoolData(currpage) { // 学校列表
        this.loading = true
        const data = {
          page: currpage, // 当前页数
          limit: this.pagesize ,// 每页条数
          area: 'undefined' ,
          school: 'undefined'
        }
        schoolData(data).then(res => {
          this.data = res.data.List // 学校列表
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      schoolDataSearch(val) {// 学校列表搜索
        this.cityid = this.searchCity;//点击查询时 把当下拉列表this.searchCity的值赋给this.cityid 然后点击下一页的时候 因为 this.cityid 应经有值了 会走handleCurrentChange的 this.schoolDataSearch(val) 这个 如果此时换了地区 就要调用 city(scity) 方法 把this.cityid = "" 然后就会走 handleCurrentChange 的this.schoolData(val)
        const data = {
          page: val, // 当前页数
          limit: this.pagesize ,// 每页条数
          area: this.searchCity ,// 查询城市
          school: this.searchSchool // 查询学校
        }
        schoolData(data).then(res => {
          this.data = res.data.List // 学校列表
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.loading = false
          this.currpage = val;
        }).catch(err => {
          console.log(err)
        })
      },
      citySearch() {
        citySearch().then(res => {
          this.cityList = res.data // 地区列表
        }).catch(err => {
          console.log(err)
        })
      },
      city(scity) {
        this.searchSchool = "";//当换城市时候把 学校下拉清空
        this.cityid = "";//当换城市时候把 这个设置为空 当分页操作时 根据这个属性判断
        // 一开始选择了城市 点击查询 此时schoolDataSearch会给 this.cityid 附上值
         const data = {
           city : scity
         }
         schoolSearch(data).then(res => {
            this.schoolList = res.data // 学校列表
         }).catch(err => {
           console.log(err)
         })
      },
      handleCurrentChange(val) {// 分页
        if(this.cityid  =='' ){//
              this.schoolData(val)
        }else{
            this.schoolDataSearch(val)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  #body {
    min-height: 94%;
    overflow: hidden;
  }

  .header {
    display: flex;
    height: 70px;
    justify-content: space-around;

    div {
      p:nth-child(1) {
        font-size: 20px;
        font-weight: normal;
      }

      p:nth-child(2) {
        font-size: 14px;
        font-weight: normal;
        color: #000000;
      }
    }
  }

  .ClassInfo_box {
    width: 96%;
    margin: 0 auto;
    background: white;
    margin-top: 25px;

    .chartTitle {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid #cccccc;

      .facility {
        font-size: 16px;
        line-height: 24px;
        color: #1890FF;
        margin-left: 5px;
        font-family: PingFangSC-Medium;
      }
    }
  }
  .query {
    width: 98%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 32px;

    .queryLeft {
      width: 80%;
      display: flex;

      div {
        width: 22%;
        text-align: left;

        .input,
        .select {
          width: 60%;
        }
      }
    }
  }
</style>
